import React from 'react';
import Container from '../Container';
import BannerBand from '../../svgs/banner-band.svg';
import Check from '../../svgs/institutions/check.svg';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import InstitutionsAnimation from '../InstitutionsAnimation';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const PromotionsBanner = () => {
  const intl = useIntl();
  const langMap = {
    en: 'EN',
    'zh-cn': 'CN',
    'zh-hk': 'HK',
  };
  const data = useStaticQuery(graphql`
    query {
      SignUP: file(relativePath: { eq: "footer/footer-signUp.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <>
      <div
        css={css`
          // background: #f2f2f2;
          width: 100%;
          background: #ee2c78;
          display: flex;
          justify-content: center;

          // padding-top: 20px;
          z-index: 1;
          overflow: hidden;
          min-width: 320px;
          height: 200px;
          ${Tablet} {
            // padding-top: 30px;
            height: 220px;
          }
          ${Desktop} {
            // padding-top: 40px;
            height: 307px;
          }
        `}
      >
        <Container
          css={css`
            position: relative;
            ${Tablet} {
            }
          `}
        >
          {/* [字] */}
          <div
            css={css`
              color: #ffffff;
              font-weight: bold;
              font-size: 32px;
              line-height: 36px;
              margin-top: 79px;
              ${Tablet} {
                font-size: 48px;
                line-height: 52px;
                margin-top: 84px;
              }
              ${Desktop} {
                font-size: 48px;
                line-height: 52px;
                margin-top: 118px;
              }
            `}
          >
            {intl.formatMessage({ id: `promotions.banner-title` })}
          </div>
          {/* [图] */}
          <div
            css={css`
              position: absolute;
              bottom: 0px;
              right: -50px;
              z-index: -1;
              ${Tablet} {
                // bottom: -10px;
                // right: -89px;
                bottom: 0px;
                right: 56px;
              }
              ${Desktop} {
                bottom: 0;
                right: 85px;
              }
            `}
          >
            <Img
              fluid={data[`SignUP`].childImageSharp.fluid}
              imgStyle={{ objectFit: 'fill' }}
              fadeIn={false}
              loading='eager'
              css={css`
                position: absolute;
                width: 260px;
                // transform: translate(-189px, 12px);
                ${Tablet} {
                  width: 296px;
                  // height: 340px;
                  // transform: translate(-267px, 165px);
                }
                ${Desktop} {
                  width: 420px;
                  // right: 0;
                  // transform: translate(-364px, -39px);
                }
              `}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default PromotionsBanner;
