import { css } from '@emotion/core';
import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PromotionsBanner from '../components/PromotionsBanner';
import { Desktop, Tablet } from '../constants/responsive';
import Container from '../components/Container';
import { graphql, useStaticQuery } from 'gatsby';
import Blank from '../components/Blank';
import JumpTo from '../components/JumpToLink';

const Activity = ({ promotion }) => {
  return (
    <Container
      css={css`
        display: flex;
        flex-direction: column;
        position: relative;
      `}
    >
      {/* title */}
      <JumpTo url={promotion.link}>
        <div
          css={css`
            width: fit-content;
            cursor: pointer;
            color: #000000;

            font-size: 24px;
            font-weight: bold;
            line-height: 1.25;
            text-decoration: underline;
            text-underline-offset: 5px;
            margin-bottom: 20px;
            ${Tablet} {
              font-size: 36px;
              font-weight: 500;
              line-height: 1.33;
              margin-bottom: 20px;
            }
            ${Desktop} {
              font-size: 36px;
              line-height: 33px;
              font-weight: bold;
              margin-bottom: 20px;
            }
          `}
        >
          {promotion.title}
        </div>
      </JumpTo>
      {/* description */}
      <div
        css={css`
          font-size: 14px;
          font-weight: normal;
          line-height: 1.71;
          margin-bottom: 10px;
          ${Tablet} {
            font-size: 18px;
            font-weight: normal;
            line-height: 1.39;
            margin-bottom: 20px;
          }
          ${Desktop} {
            font-weight: normal;
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 20px;
          }
        `}
      >
        {promotion.description}
        <JumpTo url={promotion.link}>
          <span
            css={css`
              text-decoration: underline;
              cursor: pointer;
              color: #000000;
              text-underline-offset: 5px;
            `}
          >
            {promotion.addition}
          </span>
        </JumpTo>
      </div>
      {/* 图 */}
      <JumpTo
        url={promotion.link}
        css={css`
          align-self: center;
        `}
      >
        <div
          css={css`
            cursor: pointer;
            position: relative;
            border-radius: 8px;
            width: 295px;
            height: 428px;
            background: url(${promotion.image}) no-repeat;
            background-size: cover;
            background-position: center;
            ${Tablet} {
              width: 674px;
              height: 329px;
              background: url(${promotion.pc_image}) no-repeat;
              background-size: cover;
            }
          `}
        ></div>
      </JumpTo>
      <Blank height={[50, 100, 150]} />
    </Container>
  );
};

const PromotionsPage = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allPromotions {
        edges {
          node {
            id
            addition
            addition_link
            back_color
            button_back_color
            button_text
            button_text_color
            description
            image
            ipad_image
            link
            locale
            pc_image
            substract
            title
          }
        }
      }
    }
  `);
  const promotions = data.allPromotions.edges
    .map((node) => node.node)
    .filter((promotion) => promotion.locale === intl.locale);
  return (
    <Layout footerForm footerBlank>
      <SEO
        title={intl.formatMessage({
          id: 'promotions.SEO.title',
        })}
      />
      <PromotionsBanner />
      <Blank height={[50, 100, 150]} />
      {/* 没有活动 */}
      {promotions.length === 0 && (
        <div
          css={css`
            color: #000;
            font-size: 24px;
            line-height: 1.33;
            margin-bottom: 200px;
            max-width: 335px;
            text-align: center;
            font-weight: 500;
            ${Tablet} {
              font-size: 36px;
              line-height: 1.33;
              margin-bottom: 100px;
              max-width: none;
            }
            ${Desktop} {
              font-size: 36px;
              line-height: 0.92;
              margin-bottom: 100px;
            }
          `}
        >
          {
            {
              en: 'Stay tuned for more promotions!',
              'zh-cn': '活动即将推出，敬请期待！',
              'zh-hk': '活動即將推出，敬請期待！',
            }[intl.locale]
          }
        </div>
      )}
      {/* 最少有一个活动 */}
      {promotions.length > 0 &&
        promotions.map((promotion) => {
          return <Activity promotion={promotion} key={promotion.id} />;
        })}
    </Layout>
  );
};
export default PromotionsPage;
