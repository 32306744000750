import React from 'react';
import { Link } from 'gatsby-plugin-intl';

const JumpTo = ({ children, url = ``, ...rest }) => {
  // 该组件用来解决不知道是跳转站内还是站外链接的问题
  if (url[0] === '/') {
    // 链接跳转站内
    return (
      <Link to={url} {...rest}>
        {children}
      </Link>
    );
  } else {
    // 链接跳转站外
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }
};

export default JumpTo;
